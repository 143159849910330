import { render, staticRenderFns } from "./GameOverMenu.vue?vue&type=template&id=194b432e&scoped=true&"
import script from "./GameOverMenu.vue?vue&type=script&lang=js&"
export * from "./GameOverMenu.vue?vue&type=script&lang=js&"
import style0 from "./GameOverMenu.vue?vue&type=style&index=0&id=194b432e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "194b432e",
  null
  
)

export default component.exports